var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Акции")]),_c('stock-filters',{staticClass:"mb-5",attrs:{"table-params":_vm.tableParams},on:{"change-field":_vm.changeField}}),_c('data-table',{attrs:{"url":"stocks","item-key":"ticker","clickable-rows":"","headers":_vm.headers,"table-params":_vm.tableParams},on:{"click:row":_vm.editStock},scopedSlots:_vm._u([{key:"item.ticker",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[_c('div',[_c('v-img',{staticClass:"rounded-circle",attrs:{"src":item.logo,"width":"44","height":"44"}})],1),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(item.ticker)+" ")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.status.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$store.getters["forms/statusTitleByValue"](item.status.value))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt))+" ")]}},{key:"item.prices.current.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.prices.current.time))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }